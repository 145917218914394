<template>
  <div>
    <br />
    <div class="d-flex justify-content-between align-items-center p-0">
      <div class="col-auto">
        <span class="p-2">
          Facture Référence Nº :
          <span class="fw-bold">
            {{ $route.params.reference }}
          </span>
        </span>
      </div>
      <div class="col-auto row">
        <div v-if="purchase.reference" class="col-auto p-1">
          <button @click="refresh()" class="btn p-0">
            <i class="bi bi-check2-square text-secondary fs-4"></i>
          </button>
        </div>

        <div v-if="purchase.reference" class="col-auto p-1">
          <button
            @click="
              $router.push({
                name: 'purchases-edit',
                params: { reference: purchase.reference },
              })
            "
            class="btn p-0"
          >
            <i class="bi bi-pencil-square text-secondary fs-4"></i> Modifier
          </button>
        </div>
        <div v-if="purchase.reference" class="col-auto p-1">
          <button class="btn p-0">
            <i class="bi bi-printer text-secondary fs-4"></i>
          </button>
        </div>
        <div class="col-auto p-1">
          <button @click="refresh()" class="btn p-0">
            <i class="bi bi-arrow-clockwise text-secondary fs-4"></i>
          </button>
        </div>
      </div>
    </div>
    <hr />
    <div class="row align-items-stretch">
      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">Client</div>
          <div class="col p-1 fw-bold">
            <span v-if="purchase.supplier">
              {{ purchase.supplier.fullName }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Nº de Téléphone</div>
          <div class="col p-1 fw-bold">
            <span v-if="purchase.supplier">
              {{ purchase.supplier.phone }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Nom Commercial (Société)</div>
          <div class="col p-1 fw-bold">
            <span v-if="purchase.supplier">
              {{ purchase.supplier.society }}
            </span>
          </div>
        </div>
      </div>
      <!-- ***** -->
      <div class="col-4 justify-content-between">
        <div class="row">
          <div class="col p-1">Quantité totale</div>
          <div v-if="purchase.totalQuantity" class="col p-1 fw-bold">
            {{ purchase.totalQuantity }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Total TTC</div>
          <div v-if="purchase.totalPriceTTC" class="col p-1 fw-bold">
            {{ purchase.totalPriceTTC }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Total HT</div>
          <div v-if="purchase.totalPriceHT" class="col p-1 fw-bold">
            {{ purchase.totalPriceHT }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Total TVA</div>
          <div v-if="purchase.totalPriceTVA" class="col p-1 fw-bold">
            {{ purchase.totalPriceTVA }}
          </div>
        </div>
      </div>
      <!--  -->
      <div class="col-4 justify-content-between">
        <div class="row">
          <div class="col p-1">Total Payé :</div>
          <div class="col p-1 fw-bold">
            {{ purchase.amountPaid }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Impayé :</div>
          <div class="col p-1 fw-bold">
            {{ purchase.amountUnpaid }}
          </div>
        </div>
      </div>
    </div>

    <br />
    <div class="row align-items-stretch">
      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">État de livraison</div>
          <div class="col p-1 fw-bold">
            <span v-if="purchase.supplier"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Livré par :</div>
          <div class="col p-1 fw-bold">
            <span v-if="purchase.supplier"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Livré le :</div>
          <div class="col p-1 fw-bold">
            <span> {{ purchase.date }} </span>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">Vérifié (oui/non) :</div>
          <div class="col p-1 fw-bold">
            <span v-if="purchase.supplier"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Vérifié par :</div>
          <div class="col p-1 fw-bold">
            <span v-if="purchase.supplier"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Vérifié à :</div>
          <div class="col p-1 fw-bold">
            <span v-if="purchase.supplier"> - </span>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">Validé (oui/non) :</div>
          <div class="col p-1 fw-bold">
            <span v-if="purchase.supplier"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Validé par :</div>
          <div class="col p-1 fw-bold">
            <span v-if="purchase.supplier"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Validé à :</div>
          <div class="col p-1 fw-bold">
            <span v-if="purchase.supplier"> - </span>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Nom</th>
          <th scope="col">Quantité</th>
          <th scope="col">Prix U (TTC)</th>
          <th scope="col">M. Total</th>
          <th scope="col">Stock</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in purchase.purchase_items" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ item.item_reference }}
          </td>
          <td>
            <span v-if="item.item">
              {{ item.item.fullName }}
            </span>
          </td>
          <td>{{ item.quantity }}</td>
          <td class="text-warning">
            {{ item.priceTTC }}
          </td>
          <td class="text-warning">
            {{ item.totalPriceTTC }}
          </td>
          <td>
            <span v-if="item.stock">
              {{ item.stock.fullName }} / {{ item.stock.reference }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col-1">Nº</th>
          <th scope="col-2">Référence</th>
          <th scope="col-2">Date</th>
          <th scope="col-2">Mode</th>
          <th scope="col-2">M. Payé</th>
          <th scope="col-2">M. à Régler</th>
          <th scope="col-2">Créer par</th>
          <th scope="col">Remarque</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(payment, index) in purchase.payments" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ payment.reference }}
          </td>

          <td>{{ payment.date }}</td>

          <td class="text-warning">
            <span>
              {{ payment.paymentMethod }}
            </span>
          </td>
          <td class="text-success">
            <span v-if="payment.amount >= 0">
              {{ payment.amount }}
            </span>
          </td>
          <td class="text-danger">
            <span v-if="payment.remainingAmount >= 0">
              {{ payment.remainingAmount }}
            </span>
          </td>
          <td>
            <span v-if="payment.user">
              {{ payment.user.name }}
            </span>
          </td>
          <td>
            <span>
              {{ payment.remark }}
            </span>
          </td>
          <td class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'payments-details',
                  params: { reference: payment.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-info-square fs-5"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },

  mounted() {
    this.$store.dispatch("purchase/show", this.$route.params.reference);
  },
  computed: {
    ...mapGetters("purchase", {
      purchase: "getPurchase",
    }),
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("purchase/show", this.$route.params.reference);
    },
    async recalculationPrices(reference) {
      await this.$store.dispatch("purchase/recalculationPrices", reference);
    },
  },
};
</script>
